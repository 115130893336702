<template>
    <div v-if="unitDetails"
         class="rt-unit-container"
    >
        <div class="rt-unit-header py-50 pl-2"><b-link :to="{name:'auth-login'}"><b-avatar :src="logo" size="4rem" class="rt-header-logo"/></b-link></div>
        <div class="rt-unit-body">
            <b-row class="mx-1">
                <b-col cols="12" md="8">
                            <b-card no-body class="p-1">
                                <map-carousel :images='unitImages' :address='unitAddress' @modal-photos="openModal" :key="mapCarouselKey" btnId="normal"/>
                            </b-card>
                </b-col>
                <b-col cols="12" md="4" class="rt-float-column">
                    <b-card no-body class="p-1">
                        <b-card-body class="px-1 pt-50 pb-25">
                            <h2 class="mb-50">{{ unitDetails.PropertyTitle }}</h2>
                            <h3 class="mb-0 text-success">{{ {amount: unitCost, currency: (unitDetails.PropertyCurrency || 'EUR')} | currency}}</h3>
                            <div class="d-flex border-bottom mb-1 pb-1" >
                                <p class="m-0 mr-1"><b>{{ i18nT(`Loyer CC`) }}</b>
                                    {{{amount: unitDetails.PropertyRent, currency: (unitDetails.PropertyCurrency || 'EUR')} | currency }}
                                </p>
                                <p class="m-0"><b>{{ i18nT(`Charges`) }}</b>
                                    {{{amount: unitDetails.PropertyMaintenance, currency: (unitDetails.PropertyCurrency || 'EUR')} | currency }}
                                </p>
                            </div>
                            <h4>{{unitType}}</h4>
                            <div class="ren-property-rooms border-bottom pb-1">
                                <span v-if="unitDetails.PropertySize">
                                    <RtIcon icon="home" variant="light" :size="20"/>
                                    <span class="ml-50 font-medium-1">{{ unitDetails.PropertySize }} m²</span>
                                </span>
                                <span v-if="unitDetails.PropertyRoomsNum">
                                    <RtIcon icon="door-open" variant="light" :size="20"/>
                                    <span class="ml-50 font-medium-1">{{ unitDetails.PropertyRoomsNum }}</span>
                                </span>
                                <span v-if="unitDetails.PropertyRoomsNumChambres">
                                    <RtIcon icon="bed-front" variant="light" :size="20"/>
                                    <span class="ml-50 font-medium-1">{{ unitDetails.PropertyRoomsNumChambres }}</span>
                                </span>
                                <span v-if="unitDetails.PropertyRoomsNumBaths">
                                    <RtIcon icon="shower" variant="light" :size="20"/>
                                    <span class="ml-50 font-medium-1">{{ unitDetails.PropertyRoomsNumBaths }}</span>
                                </span>
                            </div>
                            <h4 class="mt-1">
                                <RtIcon icon="location-dot" variant="light mr-25" :size="16"/>
                                {{ i18nT(`Adresse`) }}
                            </h4>
                            <div v-if="unitDetails.PropertyPublicAddress === 1">

                                <p class="m-0">{{ unitDetails.PropertyAddress }}</p>
                                <p class="m-0">{{ unitDetails.PropertyPostalCode }} {{ unitDetails.PropertyCity }}</p>
                                <p class="m-0">{{ unitDetails.PropertyCountry }}</p></div>
                            <div v-else>
                                <p>{{i18nT(`Pas d'information`)}}</p>
                            </div>
                            <div class="d-flex justify-content-between align-items-center mt-1">
                                <b-button variant="outline-primary" class="rt-public-button">
                                    <RtIcon icon="phone" variant="light mr-50 mb-25" :size="16"/>
                                    <span>{{ i18nT(`Afficher le`) }}</span>
                                </b-button>
                                <b-button variant="outline-primary" class="rt-public-button">
                                    <RtIcon icon="paper-plane" variant="light mr-50 mb-25" :size="16"/>
                                    <span>{{ i18nT(`Message`) }}</span>
                                </b-button>
                            </div>
                            <div class="d-flex justify-content-center align-items-center w-100 mt-1">
                                <b-button class="w-100" variant="primary">
                                    {{ i18nT(`Postuler`) }}
                                </b-button>
                            </div>
                        </b-card-body>
                    </b-card>
                </b-col>
                <b-col cols="12" md="8" v-if="unitExtras.length>0">
                    <b-card no-body class="p-2">
                        <h3>{{i18nT(`Equipments`)}}</h3>
                        <div v-for="(extra,index) in unitExtras" :key="'extra'+index">
                                <span class="mr-50">
                                    <RtIcon icon="check" variant="light text-primary"/>
                                </span>
                            {{ i18nT(extra.label) }}
                        </div>
                    </b-card>
                </b-col>
                <b-col cols="12" md="8">
                    <b-card no-body class="p-2" >
                        <div v-if="checkDescription">
                            <h3>{{ i18nT(`Description`) }}</h3>
                            <p v-if="unitDetails.PropertyComments" class="m-0 mb-1">{{
                                    unitDetails.PropertyComments
                                }}</p>
                            <p v-if="unitDetails.PropertySize" class="m-0"><b>{{ i18nT(`Surface`) }}: </b>
                                {{ unitDetails.PropertySize }}</p>
                            <p v-if="unitDetails.PropertyRoomsNum" class="m-0"><b>{{ i18nT(`Pièces`) }}: </b>
                                {{ unitDetails.PropertyRoomsNum }}</p>
                            <p v-if="unitDetails.PropertyRoomsNumChambres === 0 || unitDetails.PropertyRoomsNumChambres"
                               class="m-0"><b>{{ i18nT(`Chambres`) }}: </b> {{ unitDetails.PropertyRoomsNumChambres }}
                            </p>
                            <p v-if="unitDetails.PropertyRoomsNumBaths === 0 || unitDetails.PropertyRoomsNumBaths"
                               class="m-0"><b>{{ i18nT(`Salles de bain`) }}: </b> {{
                                    unitDetails.PropertyRoomsNumBaths
                                }}</p>
                            <p v-if="unitDetails.PropertyFloor" class="m-0 mt-2 pb-1 border-bottom">
                                <b>{{ i18nT(`Etage`) }}: </b> {{ unitDetails.PropertyFloor }}</p>
                        </div>
                        <div class="mt-1 pb-1" v-if="unitDetails.PropertyEnergyConsumptionIndex" :class="{'border-bottom': unitDetails.PropertyGreenhouseIndex }">
                            <h3>{{i18nT(`Consommations énergétiques`)}}</h3>
                            <h4 class="mt-2 ml-25">{{unitDetails.PropertyEnergyConsumptionIndex}} {{unitDetails.PropertyEnergyConsumption}}</h4>
                            <div v-for="(info, index) in energyConsumptionScale" :key="'scaleInfo' + index" class="d-flex align-items-center">
                                <div  :class="`ren-arrow-box ${info.color}`" :style="'width:' + info.boxWidth">
                                    <span>{{ i18nT(info.text) }}</span> <b class="float-right">{{info.energyIndex}}</b>
                                </div>
                                <RtIcon v-if="info.energyIndex == unitDetails.PropertyEnergyConsumptionIndex" icon="chevrons-left" variant="light ml-2" :size="18"/>
                            </div>
                            <p class="mb-1 mt-1">{{i18nT(`Montant estimé des depenses annuelles d'énergie pour un usage standard.`)}}</p>
                            <h4>{{i18nT(`De`)}} € {{unitDetails.PropertyEnergyConsumptionAmountFrom}} {{i18nT(`à`)}} € {{unitDetails.PropertyEnergyConsumptionAmountTo}} </h4>
                            <small>{{i18nT(`Consommations énergétiques (en énergie primaire) pour le chauffage, la production d'eau chaude sanitaire et le refroidissement.`)}}</small>
                            <br />
                            <small><b class="mr-50">{{i18nT(`Indice de mesure`)}}:</b>{{i18nT(`kWhEP/m2.an`)}}</small>
                        </div>
                        <div v-if="unitDetails.PropertyGreenhouseIndex">
                            <h3 class="mt-1">{{i18nT(`Emissions de gaz à effet de serre`)}}</h3>
                            <h4 class="mt-2 ml-25">{{unitDetails.PropertyGreenhouseIndex}} {{unitDetails.PropertyGreenhouse}}</h4>
                            <div v-for="(info, index) in greenHouseScale" :key="'scaleInfo' + index" class="d-flex align-items-center">
                                <div :class="[`ren-arrow-box ${info.color}`, { 'mb-1': index === greenHouseScale.length - 1 }]" :style="'width:' + info.boxWidth">
                                    <span>{{ i18nT(info.text) }}</span> <b class="float-right">{{info.energyIndex}}</b>
                                </div>
                                <RtIcon v-if="info.energyIndex == unitDetails.PropertyGreenhouseIndex" icon="chevrons-left" variant="light ml-2" :size="18"/>
                            </div>
                            <small>{{i18nT(`Emissions de gaz à effet de serre (GES) pour le chauffage, la production d'eau chaude sanitaire et le refroidissement.`)}}</small>
                            <br />
                            <small><b class="mr-50">{{i18nT(`Indice de mesure`)}}:</b>{{i18nT(`kgeqCO2/m2.an`)}}</small>
                        </div>
                    </b-card>
                </b-col>
            </b-row>
        </div>
        <div class="rt-unit-footer pl-2">
            <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      © {{ new Date().getFullYear() }}
      <b-link
          class="ml-25"
          href="https://rentila.com"
          target="_blank"
      >Rentila</b-link>
      <span class="d-none d-sm-inline-block"></span>
    </span>

                <span v-if="false"
                      class="float-md-right d-none d-md-block">{{ i18nT(`Hand-crafted`) }} &amp; {{ i18nT(`Made with`) }}
      <feather-icon
          icon="HeartIcon"
          size="21"
          class="text-danger stroke-current"
      />
    </span>
            </p>
        </div>
        <b-modal
            id="modal-map-carousel-full"
            hide-footer
            size="xl"
            content-class="modal-fullscreen"
            @close="resetCarousel"
        >
            <map-carousel :images="unitImages" :address="unitAddress" :full="true" :selected-photo="selectedPhoto" btnId="full"/>

        </b-modal>
    </div>

</template>

<script>
import {
    BCard,
    BRow,
    BCol, BCardBody, BLink, BModal,
} from "bootstrap-vue";
import MapCarousel from "@/views/components/mapCarousel/MapCarousel.vue";
import logoImg from '@/assets/images/logo/logo.png'

export default {
    components: {
        BModal,
        BLink,
        BCardBody,
        MapCarousel,
        BCard,
        BRow,
        BCol,
    },
    data(){
        return{
            logo: logoImg,
            unitDetails: null,
            unitImages: [],
            unitExtras: [],
            unitType: '',
            curUnitId: this.$route.params.id,
            energyConsumptionScale: [
                {color:'dark-green', boxWidth: '50px', text: '< 70', energyIndex: 'A'},
                {color:'green', boxWidth: '70px', text: '70 à 110', energyIndex: 'B'},
                {color:'light-green', boxWidth: '90px', text: '110 à 180', energyIndex: 'C'},
                {color:'yellow', boxWidth: '110px', text: '180 à 250', energyIndex: 'D'},
                {color:'orange', boxWidth: '130px', text: '250 à 330', energyIndex: 'E'},
                {color:'dark-orange', boxWidth: '150px', text: '330 à 420', energyIndex: 'F'},
                {color:'red', boxWidth: '170px', text: ' > 420', energyIndex: 'G'}
            ],
            greenHouseScale: [
                {color:'violet-a', boxWidth: '50px', text: '< 6', energyIndex: 'A'},
                {color:'violet-b', boxWidth: '70px', text: '6 à 11', energyIndex: 'B'},
                {color:'violet-c', boxWidth: '90px', text: '11 à 30', energyIndex: 'C'},
                {color:'violet-d', boxWidth: '110px', text: '30 à 50', energyIndex: 'D'},
                {color:'violet-e', boxWidth: '130px', text: '50 à 70', energyIndex: 'E'},
                {color:'violet-f', boxWidth: '150px', text: '70 à 100', energyIndex: 'F'},
                {color:'violet-g', boxWidth: '170px', text: '> 100', energyIndex: 'G'}
            ],
            mapCarouselKey: 0,
        }
    },
    created(){
        this.$http.get(`public/properties?id=${this.curUnitId}`)
            .then(({data}) => {
                this.unitDetails = data.data.property;
                this.unitExtras = data.data.extras || [];
                this.unitImages = data.data.images || [];
                this.unitType = data.data['property_types'].find(type => type.id === this.unitDetails.PropertyTypeID).label || ''
            })
    },
    computed: {
        unitAddress(){
            return (this.unitDetails.PropertyAddress || '') + ' ' + (this.unitDetails.PropertyPostalCode || '') + ' ' + (this.unitDetails.PropertyCity || '') + ' ' + (this.unitDetails.PropertyCountry || '')
        },
        unitCost(){
                return this.unitDetails.PropertyRent + this.unitDetails.PropertyMaintenance

        },
        checkDescription(){
            return this.unitDetails.PropertyComments || this.unitDetails.PropertySize || this.unitDetails.PropertyConstructionYear || this.unitDetails.PropertyRoomsNum || this.unitDetails.PropertyRoomsNumChambres || this.unitDetails.PropertyRoomsNumBaths || this.unitDetails.PropertyFloor
        },
    },
    methods: {
        openModal(index) {
            this.selectedPhoto = index;
            this.$bvModal.show('modal-map-carousel-full')
        },
        resetCarousel() {
            this.mapCarouselKey++
        }
    }
}

</script>

<style lang="scss">

.ren-property-info-card{
    background-color: #f8f8f8;
    border: none !important;
}

.ren-property-rooms {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
}


.rt-unit-body{
    padding: 20px;
    margin: 0 auto;
    max-width: 80%;
}

.rt-unit-body{
    @media(max-width: 576px){
        max-width: 100%;
    }
}

.rt-unit-header{
    position: sticky;
    top: 0;
    background-color: var(--primary);
    width: 100%;
    z-index: 100;
}

.rt-unit-footer{
    background-color: transparent;
    width: 100%;
    height: 30px;
    z-index: 100;
}


.rt-header-logo{
    background-color: #f8f8f8;

}

.rt-public-button{
    width: 45%;

}

.rt-float-column {
    @media (min-width: 768px){
        top:8%;
        right: 0;
        position: sticky;
    }

}

.ren-arrow-box{
    position: relative;
    background: #88b7d5;
    height: 20px;
    padding: 2px 5px;
    font-size: 12px;
    line-height: 16px;
    margin: 2px;
    color: white !important;
}

.ren-arrow-box:after {
    left: 100%;
    top: 50%;
    border: 10px solid transparent;
    content: " ";
    height: 16px;
    width: 0;
    position: absolute;
    pointer-events: none;
    margin-top: -10px;
}


/* ARROW BOX ENERGY COLORS */
.dark-green {
    background: #4CAF50;
}

.green{
    background: #54ad16;
}

.light-green{
    background: #c9d400;
}

.yellow{
    background: rgba(243, 230, 52, 0.84);
}

.orange{
    background: #fdbc00;
}

.dark-orange{
    background: #ed6900;
}

.red{
    background: #e60006;
}


.dark-green:after {
    border-left-color: #4CAF50;
}

.green:after{
    border-left-color: #54ad16;
}

.light-green:after{
    border-left-color: #c9d400;
}

.yellow:after{
    border-left-color: rgba(243, 230, 52, 0.84);
}

.orange:after{
    border-left-color: #fdbc00;
}

.dark-orange:after{
    border-left-color: #ed6900;
}

.red:after{
    border-left-color: #e60006;
}

/* ARROW BOX GREEN HOUSE COLORS */

.violet-a{
    background: #f0e1fb;
}

.violet-b{
    background: #e2c1fa;
}

.violet-c{
    background: #d5a7f7;
}

.violet-d{
    background: #cc92f5;
}

.violet-e{
    background: #bb6df2;

}

.violet-f{
    background: #a845ee;
}

.violet-g{
    background: #8b00e3;
}

.violet-a:after{
    border-left-color: #f0e1fb;
}

.violet-b:after{
    border-left-color: #e2c1fa;
}

.violet-c:after{
    border-left-color: #d5a7f7;
}

.violet-d:after{
    border-left-color: #cc92f5;
}

.violet-e:after{
    border-left-color: #bb6df2;

}

.violet-f:after{
    border-left-color: #a845ee;
}

.violet-g:after{
    border-left-color: #8b00e3;
}



</style>